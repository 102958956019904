<template>
  <div>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <h5 class="mb-0">{{ modeEdit ? $t("Edit") : $t("New") }} {{ $t("game") }}</h5>
    </div>
    <!-- BODY -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form class="p-2" @submit.prevent="handleSubmit(modeEdit ? updateGame : saveGame)" @reset.prevent="resetForm">
        <!-- Game Id -->
        <validation-provider #default="validationContext" name="gameID">
          <b-form-group :label="`${$t('game')} id`" label-for="gameID">
            <b-form-input id="gameID" v-model="game.gameID" autofocus :state="getValidationState(validationContext)"
              trim />
          </b-form-group>
        </validation-provider>

        <!--Game name-->
        <validation-provider #default="validationContext" name="gameName" rules="required">
          <b-form-group :label="$t('game-name')" label-for="gameName">
            <b-form-input id="gameName" v-model="game.gameName" autofocus :state="getValidationState(validationContext)"
              trim />
          </b-form-group>
        </validation-provider>

        <!--Product-->

        <b-form-group :label="$t('product')" label-for="productSelct">
          <validation-provider #default="{ errors }" name="Game Name" rules="required">
            <v-select id="productSelct" v-model="producSelectId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="$store.getters['productsModule/productsActives']" label="name"
              :reduce="(product) => product._id" placeholder="Select product" :clearable="false">
            </v-select>
            <small class="text-danger">{{
              errors[0] ? "Select product" : ""
            }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Brands -->
        <b-form-group :label="$t('brands')" label-for="Brand">
          <validation-provider #default="validationContext" name="Brand" rules="required">
            <v-select id="Brand" v-model="game.brandId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Select brand" :options="brandsOptions" :reduce="(item) => item._id" label="name">
            </v-select>
            <small class="text-danger">{{
              validationContext.errors[0] ? "Select brand" : ""
            }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Categories -->
        <b-form-group :label="$t('categories')" label-for="categoriesName">
          <validation-provider #default="validationContext" name="Categories" rules="required">
            <v-select id="categoriesName" multiple push-tags v-model="game.categories"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Select categories or add new"
              :options="categoriesOptions" :reduce="(category) => category._id" label="name">
            </v-select>
            <small class="text-danger">{{
              validationContext.errors[0] ? "Select categorie" : ""
            }}</small>
          </validation-provider>
        </b-form-group>


        <!-- tags -->
        <b-form-group :label="$t('tags')" label-for="tagsName">
          <v-select id="tagsName" taggable multiple push-tags v-model="game.tags"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            placeholder="Escriba para buscar o agregar nuevas etiquetas" :options="tagsOptions" @search="searchTags">
          </v-select>
        </b-form-group>

        <validation-provider #default="validationContext" name="urlImage">
          <b-form-group :label="$t('url-image')" label-for="urlImage">
            <b-form-input id="urlImage" v-model="game.url_image" autofocus
              :state="getValidationState(validationContext)" trim />
          </b-form-group>
        </validation-provider>

        <!-- previsualizar la imagen -->
        <!-- <input type="file" class="hidden" ref="updateImgInput" @change="handleFileUpload" accept="image/*" /> -->

        <!-- 
        <div class="flex justify-between ">
                        <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*" />
                        <vs-button type="border" size="small" @click="$refs.updateImgInput.click()">Actualizar Logo</vs-button>
                        <vs-button size="small" type="border" color="danger" @click="empresa.imgLogo = null">Quitar Logo</vs-button>
                    </div> -->

        <!-- <div id="preview" class="mb-2">
          <img v-if="url_image" :src="url_image"  @click="$refs.updateImgInput.click()" class="shadow-lg cursor-pointer rounded" />
        </div> -->

        <!-- boton de cargar imagen -->
        <!-- <div class="d-flex align-items-center justify-content-between mb-2"> 
            <b-button
              v-if="!url_image"
              class="btn-block"
              variant="outline-primary"
              @click="$refs.updateImgInput.click()"
              >
              <feather-icon icon="UploadCloudIcon" class="mr-1"></feather-icon>
              <span>Upload image</span>
            </b-button>
            <b-button
              v-if="url_image"
              variant="outline-danger btn-block mx-5"
              @click="url_image = null"
              >
              <feather-icon icon="XIcon" class="mr-1"></feather-icon>
              <span>Remove image</span>
            </b-button>
        
        </div> -->
        <b-form-group :label="$t('private-game')" label-for="private-game">
          <b-form-checkbox v-model="game.isPrivate" id="private-game" @change="handlePrivateGameChange"
            class="custom-control-success" switch inline>
            <b>{{ game.isPrivate ? "Active" : "Unactive" }}</b>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group v-if="game.isPrivate" label="Cliente Privado" label-for="clientPrivate">
          <v-select id="clientPrivate" multiple v-model="game.customersPrivate"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Select a private client"
            :options="clientCustomer" @input="selectCustomer" :reduce="(customer) => customer._id" label="name"
            @click.stop>
            <template #option="{ name }">
              <feather-icon icon="UserIcon" size="16" class="align-middle mr-50" />
              <span> {{ name }}</span>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group :label="$t('labels.status')" label-for="status">
          <b-form-checkbox v-model="game.status" id="status" class="custom-control-success" switch inline>
            <b>{{ game.status ? "Active" : "Unactive" }}</b>
          </b-form-checkbox>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex align-items-center justify-content-between mt-4">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="modeEdit ? 'success' : 'primary'" class="mr-2"
            type="submit">
            <feather-icon :icon="modeEdit ? 'RefreshCcwIcon' : 'PlusIcon'" class="mr-50" />
            <span class="align-middle">{{ modeEdit ? $t("buttons.update") : $t("buttons.add") }}</span>

          </b-button>
          <b-button @click="$emit('hiddentSidebar')" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
            variant="outline-danger">
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle"> {{ $t('buttons.cancel') }} </span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormFile,
  BImg,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import gameStoreModule from "@/store/games";
import store from "@/store";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import customersStoreModule from "@/store/customers";
export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    BOverlay,
    BFormFile,
    BImg,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    activeSidebarAddGame: { type: Boolean, required: true },
  },

  data() {
    return {
      required,
      modeEdit: false,
      loadingData: false,
      producSelectId: "",
      tagsOptions: [],
      image: null,
      game: {
        gameID: "",
        gameName: "",
        categories: [],
        product: "",
        tags: [],
        brandId: "",
        status: true,
        isPrivate: false,
        url_image: null,
        customersPrivate: [],
      },
      gameOld: {
        gameID: "",
        gameName: "",
        categories: [],
        product: "",
        tags: [],
        brandId: "",
        status: true,
        url_image: null,
      },
      clientCustomer: [],
      timeDelay: null,
      url_image: null,
    };
  },
  computed: {
    categoriesOptions() {
      return this.$store.state.gameCategoryModule.gamesCategories.filter(
        (category) => {
          if (category.status) return true;
          else return false;
        }
      );
    },

    brandsOptions() {
      return this.$store.getters["brandModule/brandsActives"];
    },
  },
  watch: {
    modeEdit(val) {

      if (!val) this.resetForm();
    },
    activeSidebarAddGame(val) {
      if (this.game.isPrivate) this.handlePrivateGameChange(true);
      this.producSelectId = this.$route.params.producSelectId;
      if (val) {
        this.getBrands();
        this.getCategory();
        this.fetchTagsOptions();
        if (!val) this.resetForm();
      }
    },

  },
  methods: {
    async handlePrivateGameChange(newValue) {
      if (newValue) {
        this.loadingData = true;
        try {
          const product_id = this.producSelectId;
          const res = await this.$store.dispatch("customersModule/getCustomers", {
            product_id,

          });
          this.clientCustomer = res.data.customers;
        } catch (error) {
          this.loadingData = false;
          console.log(error);

        } finally {
          this.loadingData = false;
        }
      } else {
        this.clientCustomer = [];
        this.game.customersPrivate = [];
      }
    },
    selectCustomer(customer) {
      console.log('customer', customer, this.game.customersPrivate)

    },
    // Save game
    async saveGame() {
      try {
        this.game.product = this.producSelectId;
        this.game.brandName = this.brandsOptions.find(
          (brand) => brand._id == this.game.brandId
        ).name;
        this.loadingData = true;
        // conertr form data
        const gameData = await this.convertFormData(this.game);

        await this.$store.dispatch("gamesModule/addGame", {
          gameData,
          product: this.game.product,
        });
        this.resetForm();
        this.$emit("hiddentSidebar");
        if (this.$route.params.producSelectId == this.producSelectId) {
          this.$emit("getGames");
        }
        if (this.$route.params.producSelectId !== this.producSelectId) {
          this.$router.push({
            params: { producSelectId: this.producSelectId },
          });
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Game added successfully",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      } catch (error) {
        console.log(error.response.data.message);
        console.log('this.game.customersPrivate', this.game.customersPrivate);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error adding game add",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    // Update game
    async updateGame() {
      try {
        this.loadingData = true;
        this.game.brandName = this.brandsOptions.find(
          (brand) => brand._id == this.game.brandId
        ).name;
        this.game.product = this.producSelectId;
        // Esto porque hay disparidad en los campos que se recibe en el backend
        this.game.gameId = this.game.gameID;
        // conertir form data
        const gameData = await this.convertFormData(this.game);
        let json = {
          gameData,
          gameDataOld: this.gameOld,
        }

        console.log('json', json)
        await this.$store.dispatch("gamesModule/updateGame", {
          gameData,
          gameDataOld: this.gameOld,
        });
        this.modeEdit = false;
        this.$emit("hiddentSidebar");
        if (this.$route.params.producSelectId == this.producSelectId) {
          this.$emit("getGames");
        }
        if (this.$route.params.producSelectId !== this.producSelectId) {
          this.$router.push({
            params: { producSelectId: this.producSelectId },
          });
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Game update successfully",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      } catch (error) {
        console.log(error.response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error update games add",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    //---Validate State Fields
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    // fetch tagsOptions
    async fetchTagsOptions() {
      this.tagsOptions = await this.$store.dispatch(
        "gamesModule/fetchTagsOptions"
      );
    },
    handleFileUpload(e) {
      this.game.url_image = e.target.files[0];
      this.url_image = URL.createObjectURL(this.game.url_image);
    },
    async getCategory() {
      try {
        this.loadingData = true;
        await this.$store.dispatch("gameCategoryModule/index");
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching categories list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    resetForm() {
      this.image = null;
      this.url_image = null;
      this.game = {
        gameID: "",
        gameName: "",
        product: "",
        tags: [],
        status: true,
        url_image: null,
      };
      this.$refs.refFormObserver.reset();
    },
    async searchTags(searchTags) {
      if (this.timeDelay) clearTimeout(this.timeDelay);

      // search delay
      this.timeDelay = setTimeout(async () => {
        this.tagsOptions = await this.$store.dispatch(
          "gamesModule/fetchTagsOptions",
          searchTags
        );
      }, 800);
    },

    async convertFormData(data) {
      const fd = new FormData();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          // tener en cuenta los tags
          if (key === "tags") {
            data.tags.forEach((tag) => {
              fd.append("tags[]", tag);
            });
          } else if (key === "imageCollection") {
            // viene como un objeto y quiero que sea de esta forma
            // imageCollection[url_image] = url_image
            // imageCollection[url_image_old] = url_image_old

            Object.keys(data.imageCollection).forEach((keyImage) => {
              fd.append(
                `imageCollection[${keyImage}]`,
                data.imageCollection[keyImage]
              );
            });
          } else {
            fd.append(key, data[key]);
          }
        }
      }
      return fd;
    },
    async getBrands() {
      try {
        this.loadingData = true;
        await this.$store.dispatch("brandModule/index");
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching brands list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
  },
  created() {
    // Register module
    if (!store.hasModule("gamesModule"))
      store.registerModule("gamesModule", gameStoreModule);
    if (!store.hasModule("customersModule")) store.registerModule("customersModule", customersStoreModule);

  },


  destroyed() {
    if (store.hasModule("gamesModule")) store.unregisterModule("gamesModule");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 150px;
}
</style>
